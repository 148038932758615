.MuiRating-iconFilled {
    color: black;
}

.reviews-product-wrapper {
    margin-bottom: 20px;
    &:hover {
        cursor: pointer;
    }

  
}
