@use "src/styles/vars";




.give-review-wrapper {
    background-color: #fffcf8;
    min-height: 100vh;
    padding: 50px;
    .spinner-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ffffff9e;
        width: 95vw;
        height: 100vh;
    }
    .review-form-title {

        font-weight: 600;
        color: vars.$main-color;
        padding: 0 5rem;
        font-size: 20px;

        div {
            margin-bottom: 30px;
        }
    }
    button {
        display: block;
        cursor: pointer;
        border: none;
        background-color: #d99606;
        text-transform: uppercase;
        color: white;
        padding: 10px 30px;
        font-size: 16px;
        text-align: center;
        text-decoration: none;
        font-family: "Josefin Sans", sans-serif;
        margin: 25px auto;

        &:hover {
            background-color: #d99606a8;
        }
    }

    .review-confirm {
        .text {
            font-size: 18px;
            margin: 50px 0;
            color: #80b074;
            font-weight: 600;
        }

        img {
            height: 7rem;
            margin-top: 50px;
        }
        .lesson-card-link {
            text-transform: none;
            width: fit-content;
        }
    }


    .review-form-container {
        width: 75%;
        margin: auto;

        .input-wrapper {
            padding: 0;
            margin-bottom: 20px;
        }
        

        .input-value {
            text-align: left;
            margin-bottom: 20px;
            width: 100%;
            
            textarea {
                width: 100%
            }
        }

        .input-value.error {
            color: #d73307; 
        }
        label {
            display: block;
            font-weight: 600;
            margin-bottom: 7px;
        }
    }
}


@media (max-width: 1000px) {

    .give-review-wrapper{
        padding: 30px !important;

        .review-form-title {

            padding: 0px !important;
        }

        img {
            height: 5rem !important;
        }

        .review-form-container {
            width: 100% !important;
            .input-wrapper {
                width: 100% !important;
            }
    
          
        }

      
    }



}