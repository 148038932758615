@use "src/styles/vars";

.reviews-sidebar-wrapper {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 5;
    right: 0;
    top: 0;
    background-color: rgb(0 0 0 / 71%);
    box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.466);
    display: block;
    // opacity: 0; /* Invisible au départ */


    .reviews-sidebar-reviews-wrapper {
        position: fixed;
        bottom: 0;
        right: 0;
        height: 100vh;
        width: 470px;
        background-color: #fffcf8;
        transition: inset-inline-end .3s ease-out;
        overflow-x: auto;

        .reviews-sidebar-reviews-intern-wrapper {
            position: relative;
            padding: 2rem 1.5rem;

            .spinner-wrapper {
                position: sticky;
                background: #ffffffc2;
                z-index: 1;
                top: 0;
                height: 100vh;
            }

            .button {
                padding: 15px;
                background-color: black;
                border: 2px solid black;
                color: white;
                text-transform: uppercase;
                cursor: pointer;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.589);

                }
            }

            .reviews-item {
                padding: 2rem 0;
                border-top: 1px solid lightgray;
                .review-rate-and-date{
                    display: flex;
                    justify-content: space-between;
                }

                .review-title {
                    text-align: left;
                    font-weight: 600;
                    display: block;
                }
                .review-content {
                    text-align: justify;
                    margin-top: 20px;
                }
                .review-username {
                    text-align: left;
                    margin: 10px 0;
                    font-style: italic;
                }
                .css-1nbsiwm-MuiRating-root {
                    font-size: 1rem;
                }
            
            }

            .cross {
                position: fixed;
                right: 20px;
                top: 15px;
                cursor: pointer;
            }

            .reviews-sidebar-reviews-intern-wrapper_title {
                text-transform: uppercase;
                font-size: 18px;
                text-decoration: underline;
            }

            .reviews-rating-and-numbers {
                display: flex;
                margin: 2rem auto;
                justify-content: space-between;
                align-items: center;
                border: 1px solid vars.$main-color;
                background-color: #d9960659 ;
                padding: 10px;

                .asking-review {
                    margin-top: 10px;
                    text-decoration: underline;
                    cursor: pointer;
                    font-weight: 600;
                }

                .total-rating {
                    text-align:left ;
                    font-size: 18px;
                    font-family:Arial, Helvetica, sans-serif;
                    font-weight: 600;

                    div {
                        margin-bottom: 7px;
                    }
                }
            }
        }



    }







    .reviews-sidebar-reviews-wrapper.close {
        inset-inline-end: -472px;
        display: block;

        > * {
            opacity: 0;
            transform: translateX(20px); /* Décale légèrement pour un effet fluide */
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        }
    }

    .reviews-sidebar-reviews-wrapper.open {
        inset-inline-end: 0px;
        
  
        > * {
            opacity: 1;
            transform: translateX(0);
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        }
    
    }
}


.reviews-sidebar-wrapper.close  {
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;


}

.reviews-sidebar-wrapper.open {
    opacity: 1;
    visibility: visible;

}

@media (max-width: 500px) {
    .reviews-sidebar-reviews-wrapper {
        width: 100% !important;
        height: 90vh !important;
    }
}