.return-player {
    text-align: left;
    padding: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    a:hover {
        border-bottom: 1px solid black;
    }
}
.player-and-menu-wrapper {
    display: flex;
    min-height: 80vh;
    max-height: 100vh;
    position: relative;
    .to-open-chapter {
        &.displayNone {
            display: none;
        }
        &.display {
            cursor: pointer;
            display: flex;
            align-items: center;
            position: absolute;
            top: 10px;
            z-index: 1;
            transform: translateX(-70%);
            background-color: #f9f5f0bb;
            font-weight: 600;
            padding: 10px;

            img {
                margin-left: 5px;
                width: 32px;
            }
            &:hover {
                transform: translateX(0%);
                transition: transform 0.2s;
            }
        }
    }

    .chapter-menu-wrapper {
        overflow: auto;

        &.open {
            transform: translateX(0%);
            transition: transform 0.2s;
            z-index: 2;

            width: 40%;
        }
        &.closed {
            transform: translateX(-100%);
            width: 0%;
            transition: transform 0.2s;
        }
    }
    .player-wrapper {
        position: relative;
        width: 100%;
        video {
            object-fit: contain;
            background-color: black;
        }
        .player-media-logo {
            width: 100px;
        }

        div {
            margin: auto;
        }
        .chat-wrapper {
            position: absolute;
            top: 0;
            left: 50%;
            background-color: #f9f5f0;
        }
    }
}

.spinner-wrapper {
    height: 90vh;
}

@media (max-width: 1000px) {
    .chapter-menu-wrapper.open {
        top: 0;
        position: absolute;
        z-index: 2;
        width: 92% !important;
        height: 100% !important;
    }
}
