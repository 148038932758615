.overlay-wrapper {
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.911);
    position: absolute;
    top: 0;
    z-index: 2;

    .overlay-element-wrapper {

        .image-wrapper {
            height: 80px;
            margin-top: 80px;

        }
        img {
            width: 80px;
        }

        .text {
            margin: 20px auto;
            font-size: 20px;

        }
        .big {
            text-transform: uppercase;
            font-weight: 600;
            color: #d99606;
        }
        .bold {
            font-weight: 600;
        }

        .overlay-button {

            button {
                padding: 10px;
                margin: 10px 10px;
                text-transform: uppercase;
                cursor: pointer;
    
                &:hover {
                    background-color: #FFFCF8;
                    color: #d99606;
                }
            }

            .normal {
                background-color: #d99606;
                border: 2px solid #d99606;
                color: white;
            }

            .close-button {
                background-color: black;
                border: 2px solid black;
                color: white;
                &:hover {
                    background-color: white;
                    color: black;
                }
            }

        }
    }


}
.overlay-wrapper > * {
    z-index: 2;
}