@use "src/styles/vars";

.mediadashboard-wrapper {
    padding: 20px 30px;
    text-align: left;
    min-height: 100vh;

    .ask-review-wrapper {
        margin: 30px;
        text-align: right;
        display: flex;
        justify-content: end;
    }

    .condition-title {
        margin: auto;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 20px;
        border-bottom: 2px solid black;
        margin-bottom: 40px;
        width: fit-content;
    }
    .spinner-wrapper {
        height: 50vh;
    }

    .return {
        font-weight: 600;
        width: fit-content;
        border-bottom: 1px solid white;
        &:hover {
            border-bottom: 1px solid black;
        }
    }
    .videos-and-pdf-wrapper {
        margin: 30px 0;
        .media-dashboard-title {
            font-weight: 600;
            font-size: 17px;
            &.only {
                margin-top: 10px;
                text-align: center;
            }
            .only-cours-title {
                display: block;
                margin-bottom: 5px;
            }
            .cours-title {
                display: block;
                padding: 5px 0;
                border-bottom: 2px solid black;
                margin-bottom: 10px;
                line-height: 20px;
            }
            .cours-teacher {
                font-weight: 300;
                display: block;
                margin-bottom: 10px;
            }
        }
        .img-and-pdf-wrapper {
            .white-filter {
                background-color: #fffcf86e;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .img-card-wrapper {
                position: relative;
                .main-image-cours {
                    width: 100%;
                    object-fit: cover;
                }
                .player-logo {
                    top: 40%;
                    left: 40%;
                    width: 17%;
                    position: absolute;
                    z-index: 1;
                }
                .progression-bar {
                    position: absolute;
                    background-color: #fffcf8;
                    height: 12px;
                    width: 100%;
                    bottom: 0;
                    .progression-bar-color {
                        position: absolute;
                        bottom: 0;
                        height: 12px;
                        // width: 80%;
                        background-color: vars.$main-color;
                    }
                }
            }
            .document-pdf-wrapper {
                margin-top: 20px;
                .short-description-wrapper {
                    margin-bottom: 30px;
                }
                .pdf-item {
                    width: 100%;
                    margin-bottom: 15px;
                    a {
                        font-weight: 600;
                        display: flex;
                        align-items: center;

                        .pdf-logo {
                            width: 35px;
                            margin-right: 10px;
                        }
                        .pdf-title {
                        }
                    }
                }
            }
        }
    }
    .document-pdf-wrapper {
        margin-top: 20px;
        .pdf-item {
            width: 100%;
            margin-bottom: 15px;
            a {
                font-weight: 600;
                display: flex;
                align-items: center;
                .pdf-logo {
                    width: 35px;
                    margin-right: 10px;
                }
                .pdf-title {
                }
            }
            a.center {
                justify-content: center;
            }
        }
    }
}
@media (min-width: 1000px) {
    .mediadashboard-wrapper {
        padding: 20px 10%;
        .videos-and-pdf-wrapper {

            .img-and-pdf-wrapper {
                display: flex;
                justify-content: center;
                .img-card-wrapper {
                    width: 40%;
                    .main-image-cours {
                        height: 300px;
                    }
                    .player-logo {
                        width: 15% !important;
                    }
                }
                // padding-left: 20%;
                .document-pdf-wrapper {
                    width: 60%;
                    padding-left: 30px;
                }
            }
        }
    }
}

@media (min-width: 1550px) {
    .mediadashboard-wrapper {
        .main-image-cours {
            height: 500px !important;
        }
    }
}
