@use "src/styles/vars";


.review-form-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgb(143 138 131 / 70%);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    z-index: 10;

    .spinner-wrapper {
        height: 100%;
        width: 100%;
        background: #ffffff91;
        position: absolute;
    }

    .cross {
        font-weight: 600;
        position: absolute;
        right: 15px;
        top: 12px;    
        cursor: pointer;
    }

    .review-form {
        background-color: #fffcf8;
        position: fixed;
        top : 10%;
        left:25%;
        width: 50vw;
        height: 80vh;
        box-shadow:0px 4px 22px rgb(0 0 0 / 69%);

        button {
            display: block;
            cursor: pointer;
            border: none;
            background-color: #d99606;
            text-transform: uppercase;
            color: white;
            padding: 10px 30px;
            font-size: 16px;
            text-align: center;
            text-decoration: none;
            font-family: "Josefin Sans", sans-serif;
            margin: 25px auto;

            &:hover {
                background-color: #d99606a8;
            }
        }

        .review-confirm {
            div {
                font-size: 18px;
                margin: 50px 0;
                color: #80b074;
                font-weight: 600;
            }

            img {
                height: 7rem;
            }
        
        }

        .review-form-title {
            margin: 50px auto;
            font-weight: 600;
            color: vars.$main-color;
            padding: 0 5rem;
            font-size: 20px;

            div {
                margin-bottom: 30px;
            }
        }

        .review-form-container {
            width: 75%;
            margin: auto;

            .input-wrapper {
                padding: 0;
                margin-bottom: 20px;
            }
            

            .input-value {
                text-align: left;
                margin-bottom: 20px;
                width: 100%;
                
                textarea {
                    width: 100%
                }
            }

            .input-value.error {
                color: #d73307; 
            }
            label {
                display: block;
                font-weight: 600;
                margin-bottom: 7px;
            }
        }
    }
}

@media (max-width: 1000px) {
    .review-form {
        width: 100vw !important;
        height: 100vh !important;
        top: 5% !important;
        left: 0 !important; 
    }
}