@use "src/styles/vars";

.lesson-card-link {
    color: #fff;
    width: fit-content;
    text-transform: uppercase;
    background-color: vars.$main-color;
    padding: 0.9rem;
    margin: auto;
    border: 2px solid vars.$main-color;

    a:visited {
        color: inherit;
    }

    &:hover {
        color: vars.$main-color !important;
        background-color: #fff !important;
    }
}
