.chatbutton-wrapper {
    z-index: 2;
    position: absolute;
    top: 20px;
    right: 10px;
    background: #fffcf8;
    border-radius: 50%;
    padding: 10px;

    .chat-img-wrapper {
        width: 37px;
        cursor: pointer;
        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .chat-message {
        bottom: -90px;
        background-color: #ffffffc9;
        padding: 10px;
        right: 20px;
        width: 300px;
        text-align: justify;
        position: absolute;
        animation: fadeIn 0.8s;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
